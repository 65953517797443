import { Component } from '@angular/core';
import { GlobalSpinnerService } from '../../services/global-spinner.service';
@Component({
  selector: 'global-spinner',
  template: `
        <div class="global-spinner" *ngIf="this.globalSpinnerService.globalSpinnerSignal()" >
          <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
          </div>
        </div>
  `,
  styleUrl: './global-spinner.component.scss'
})
export class GlobalSpinnerComponent {

  constructor(public globalSpinnerService: GlobalSpinnerService) { }

  ngOnInit(): void {
  }
}
