import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { catchError, throwError } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { inject } from "@angular/core";


export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const token = localStorage.getItem('access_token');
    const authService = inject(AuthService);
    const authReq = req.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
    });

    // Pass the cloned request with the updated header to the next handler
    return next(authReq).pipe(

        catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                // Handle HTTP errors

                switch (err.status) {
                    case 401:
                        console.error('Unauthorized request:', err);
                        authService.logout();
                        break;
                    case 403:
                        authService.showForbiddenError();
                        break;

                    default:
                        console.error('HTTP error:', err);
                        break;
                }
            } else {
                // Handle non-HTTP errors
                console.error('An error occurred:', err);
            }

            // Re-throw the error to propagate it further
            return throwError(() => err);
        })
    );
};
