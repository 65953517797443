<section class="hrms-notfound-wrap">
    <div class="container-fluid">
        <div class="row">
            <div class="offset-md-3 col-md-6">
              <div class="notfound-inner">
                <h1>404</h1>
                <div class="not-found-content">
                  <h4 class="text-secondary">Oops! nothing was found</h4>
                  <p>Sorry the page you are looking for does not exist</p>
                  <a routerLink="/" class="btn btn-primary">Back</a>
              </div>
              </div>
            </div>
        </div>
    </div>
  </section>
  