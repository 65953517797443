import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSpinnerService } from '../../core/services/global-spinner.service';
import { AuthService } from '../../core/auth/auth.service';
import { finalize } from 'rxjs';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalSpinnerService: GlobalSpinnerService,
    private authService: AuthService) { }

  ngOnInit() {
    this.login();
  }

  private login(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      console.log('code: ', code);
      if (code) {
        this.getClientInfo(code);
      }
    });
  }

  private getClientInfo(code: any): void {

    this.globalSpinnerService.showSpinner();

    this.authService.getLoginUserDetails(code).pipe(finalize(() => (this.globalSpinnerService.hideSpinner())))
      .subscribe({
        next: (response: any) => {
          if (response?.token) {
            localStorage.setItem('access_token', response?.token);
            const decoded: any = jwtDecode(response?.token);
            console.log(decoded);
            localStorage.setItem('userId', decoded?.user )
            this.router.navigate(['/']);
          }
        },
        error: (error: any) => {
          this.globalSpinnerService.hideSpinner();
        }
      });
  }

}
